import React, { useState } from "react";
import "./ConnectWallet.css";
import metaMaskicon from "../../Assets/images/metaMask-icon.svg";
import WalletConnectIcon from "../../Assets/images/walletconnect-icon.svg";
import TrustWalletIcon from "../../Assets/images/trustwallet.svg";
import toast, { Toaster } from "react-hot-toast";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
// import { Link } from "react-router-dom";

const loginMsg = () => toast.success("Login Successfully!");
const ConnectWallet = () => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      
      <span onClick={() => setVisible(!visible)}>
        Connect Wallet
      </span>
      <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>
          <h5 className="modal-title" id="connectWalletToggleLabel">
            Connect Wallet
            <p>
              Start by connecting with one of the wallets below. Be sure to
              store your private keys or seed phrase securely. Never share them
              with anyone.
            </p>
          </h5>
        </CModalHeader>
        <CModalBody>
          <div className="popupbtngrp">
            <div data-coreui-dismiss="modal" className="btngrp">
              <button className="pop_btn">
                <img
                  src={TrustWalletIcon}
                  alt="MetaMask"
                  width="45"
                  height="45"
                />
                Trust<span>Wallet</span>
              </button>
            </div>
            <div data-coreui-dismiss="modal" className="btngrp">
              <button
                className="pop_btn"
              >
                <img src={metaMaskicon} alt="MetaMask" width="45" height="45" />
                Meta<span>Mask</span>
              </button>
            </div>
            <div data-coreui-dismiss="modal" className="btngrp">
              <button
                className="pop_btn"
              >
                <img
                  src={WalletConnectIcon}
                  alt="WalletConnect"
                  width="45"
                  height="45"
                />
                Wallet<span>Connect</span>
              </button>
            </div>
          </div>
        </CModalBody>
      </CModal>


      {/* <div
        className="modal fade connectWalletToggle"
        id="connectWalletToggle"
        aria-hidden="true"
        aria-labelledby="connectWalletToggleLabel"
        tabIndex="1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connectWalletToggleLabel">
                Connect Wallet
                <p>
                  Start by connecting with one of the wallets below. Be sure to
                  store your private keys or seed phrase securely. Never share
                  them with anyone.
                </p>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-coreui-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="popupbtngrp">
                <div data-coreui-dismiss="modal" className="btngrp">
                  <button
                    className="oc_btn"
                    onClick={() => {
                      props.onTrustWalletClick();
                    }}
                  >
                    <img
                      src={TrustWalletIcon}
                      alt="MetaMask"
                      width="45"
                      height="45"
                    />
                    Trust<span>Wallet</span>
                  </button>
                </div>
                <div data-coreui-dismiss="modal" className="btngrp">
                  <button
                    className="oc_btn"
                    onClick={() => {
                      props.onMetamaskClick();
                    }}
                  >
                    <img
                      src={metaMaskicon}
                      alt="MetaMask"
                      width="45"
                      height="45"
                    />
                    Meta<span>Mask</span>
                  </button>
                </div>
                <div data-coreui-dismiss="modal" className="btngrp">
                  <button
                    className="oc_btn"
                    onClick={() => {
                      props.onWalletConnectClick();
                    }}
                  >
                    <img
                      src={WalletConnectIcon}
                      alt="WalletConnect"
                      width="45"
                      height="45"
                    />
                    Wallet<span>Connect</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Toaster position="top-left" reverseOrder={false} />
    </>
  );
};

export default ConnectWallet;
