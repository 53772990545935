import React from 'react'
import { FaTelegramPlane, FaRegEnvelope, FaTwitter, FaMediumM, FaInstagram, FaRedditAlien, FaFacebookF,FaYoutube } from "react-icons/fa";
import './Footer.css'
const Footer = () => {
    return (
        <footer>
            <div className="socialIcons">
                <div className="title">
                    <h2>Easy Connect With our Platform</h2>
                </div>
                <ul>
                <li>
                        {/* ....... */}
                        <a href="https://t.me/hashfair_games" rel="noreferrer" title="Telegram" target="_blank">
                            <FaTelegramPlane />
                        </a>
                    </li>
                    <li>
                        {/* ....... */}
                        <a href="https://www.youtube.com/channel/UCt_4P2eqk0Jsn5MNsWapqcA" rel="noreferrer" title="Youtube" target="_blank">
                            <FaYoutube />
                        </a>
                    </li>
                    
                    <li>
                        <a href="https://twitter.com/Hashfair_Games" rel="noreferrer" title="Twitter" target="_blank">
                            <FaTwitter />
                        </a>
                    </li>
                    <li>
                        <a href="https://medium.com/@hashfairgames" rel="noreferrer" title="Medium" target="_blank">
                            <FaMediumM />
                        </a>
                    </li>
                    <li>
                        {/* ....... */}
                        <a href="https://www.instagram.com/hashfairgames/" rel="noreferrer" title="Instagram" target="_blank">
                            <FaInstagram />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.reddit.com/r/Hashfair/" rel="noreferrer" title="Reddit" target="_blank">
                            <FaRedditAlien />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/hashfairgames" rel="noreferrer" title="Facebook" target="_blank">
                            <FaFacebookF />
                        </a>
                    </li>
                </ul>
            </div>
            <div className="copyright">
                <p>©  All Rights Reserved</p>
            </div>
        </footer>
    )
}

export default Footer