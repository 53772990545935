import React, { useState } from 'react'
import PublicSale from '../../Components/PublicSale/PublicSale'
import Projects from '../../Components/Projects/Projects'
import Faqs from '../../Components/Faqs/Faqs'
import SaleDetails from '../../Components/SaleDetailList/SaleDetailList'
import SaleCountdown from '../../Components/SaleCountdown/SaleCountdown'
import Info from '../../Components/Benefits'
import Faq6 from '../../Components/Faq6/Faq6'

const Sale = () => {
    
   
    return (
        <>
           
            <PublicSale/>  
          <SaleCountdown />
            <Info />
            <SaleDetails />
            <Faq6 />

            {/* <Projects /> */}
            {/* <Faqs /> */}
        </>
    )
}

export default Sale