import React, { useEffect, useState } from 'react'
import './RefDetails.css'

import Tooltip from "react-simple-tooltip"
import { css } from "styled-components"
import toast, { Toaster } from 'react-hot-toast';
import { localeFixed } from '../../config/helpers';
import GetReferralDetails from '../../config/getDetail';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Pagination from '@mui/material/Pagination';



const Ref = () => {

    const [walletAddress, setwalletAddress] = useState(localStorage.getItem('walletaddress'))
    const [noOfRefrral, setnoOfRefrral] = useState(0)
    const [teamSize, setTeamSize] = useState(0)
    const [spon, setSpon] = useState("")
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState([]);
    const [downUserTeam, setDownUserTeam] = useState({})

    window.addEventListener('storage', () => {
        const walletAddress = localStorage.getItem('walletaddress');
        setwalletAddress(walletAddress)

    })


    //  Code for pagination 
    const [indexOfLast, setindexOfLast] = useState(10)
    const [indexOfFirst, setindexOfFirst] = useState(0)

    function pagechange(event, value) {
        const currentPage = Number(value)
        const dataPerPage = 10

        const indexOfLastTodo = currentPage * dataPerPage;
        const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

        setindexOfFirst(indexOfFirstTodo)
        setindexOfLast(indexOfLastTodo)
    }

    // Pagination Code End 
    const GetRefferal = async () => {

        if (walletAddress !== null) {
            const test = await GetReferralDetails(walletAddress)
            const aa = test.data.referrals 
            setSpon(aa.length > 0 ?test.data.referrals[0].referrer:"")

            // Pagination Count Set
            const len = test.data.referralLevels
            setnoOfRefrral(len.length)
            setTeamSize(aa.length > 0 ? test.data.referrals[0].totalTeam : 0)

            const PageCount = Math.ceil(Number(len.length) / 10)
            setPagination(PageCount)

            //  Table Data 
            setTableData(test.data.referralLevels)
            let teams = {}
            for ( let i in len ) {
                const userTeamSize = await GetReferralDetails(len[i].user)
                teams[len[i].user] = userTeamSize.data.referrals[0].totalTeam
            }

            setDownUserTeam(teams)
        }
    }


    const navigate = useNavigate()


    function GoBack() {
        navigate("/")
    }

    useEffect(() => {
        GetRefferal()
    }, [walletAddress])

    useEffect(() => {
        GetRefferal()
    }, [])



    return (
        <>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />


            <section className="refDetails">
                <div className="oc_container">
                    <div className="title d-flex justify-content-center align-items-center flex-wrap">
                        <button className='back-btn' onClick={GoBack} ><ArrowBackIcon /></button>
                        <h2>My Community</h2>
                    </div>

                    <div className="projects">
                        <div className="oc_container">
                            <div className="row mb-5">
                                <div className="col-6 col-lg-6">
                                    <div className="row countBox align-items-center">

                                        <div className="col-sm-12">
                                            <div className="countinbox text-center">
                                                <svg baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" overflow="visible" className="sc-5f2a7416-0 jbewcx"><g fill="currentColor"><path d="M12 6c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path><path fill-opacity="0.302" d="M12 6c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path><path d="M4 19v-8c0-1.1.4-2.2 1-3H2c-1.1 0-2 .9-2 2v6h2v5c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-2H4z"></path><path fill-opacity="0.302" d="M4 19v-8c0-1.1.4-2.2 1-3H2c-1.1 0-2 .9-2 2v6h2v5c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-2H4z"></path><path d="M14 24h-4c-.6 0-1-.4-1-1v-6H6v-6c0-1.7 1.3-3 3-3h6c1.7 0 3 1.3 3 3v6h-3v6c0 .6-.4 1-1 1z"></path><path fill-opacity="0.302" d="M14 24h-4c-.6 0-1-.4-1-1v-6H6v-6c0-1.7 1.3-3 3-3h6c1.7 0 3 1.3 3 3v6h-3v6c0 .6-.4 1-1 1z"></path><path d="M4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path><path fill-opacity="0.302" d="M4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path><path d="M20 19v-8c0-1.1-.4-2.2-1-3h3c1.1 0 2 .9 2 2v6h-2v5c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2h2z"></path><path fill-opacity="0.302" d="M20 19v-8c0-1.1-.4-2.2-1-3h3c1.1 0 2 .9 2 2v6h-2v5c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2h2z"></path><path d="M20 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path><path fill-opacity="0.302" d="M20 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path></g></svg>

                                                <p>Level referrals</p>
                                                <h3>{noOfRefrral || 0}</h3>
                                                {spon &&
                                                    <div className="sponser" >
                                                        Your Sponser - {spon.substring(0, 3)}...{spon.slice(-3)}
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-6">
                                    <div className="row countBox align-items-center">
                                        <div className="col-sm-12">
                                            <div className="countinbox text-center">
                                                <svg baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" overflow="visible" className="sc-5f2a7416-0 jbewcx"><g fill="currentColor"><path d="M12 6c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path><path fill-opacity="0.302" d="M12 6c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path><path d="M4 19v-8c0-1.1.4-2.2 1-3H2c-1.1 0-2 .9-2 2v6h2v5c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-2H4z"></path><path fill-opacity="0.302" d="M4 19v-8c0-1.1.4-2.2 1-3H2c-1.1 0-2 .9-2 2v6h2v5c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-2H4z"></path><path d="M14 24h-4c-.6 0-1-.4-1-1v-6H6v-6c0-1.7 1.3-3 3-3h6c1.7 0 3 1.3 3 3v6h-3v6c0 .6-.4 1-1 1z"></path><path fill-opacity="0.302" d="M14 24h-4c-.6 0-1-.4-1-1v-6H6v-6c0-1.7 1.3-3 3-3h6c1.7 0 3 1.3 3 3v6h-3v6c0 .6-.4 1-1 1z"></path><path d="M4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path><path fill-opacity="0.302" d="M4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path><path d="M20 19v-8c0-1.1-.4-2.2-1-3h3c1.1 0 2 .9 2 2v6h-2v5c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2h2z"></path><path fill-opacity="0.302" d="M20 19v-8c0-1.1-.4-2.2-1-3h3c1.1 0 2 .9 2 2v6h-2v5c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2h2z"></path><path d="M20 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path><path fill-opacity="0.302" d="M20 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path></g></svg>

                                                <p>Team size</p>
                                                <h3>{teamSize || 0}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="refDetailsList">
                        <div className='table-responsive'>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th style={{wordBreak: "keep-all"}}>Sr.no</th>
                                        <th style={{wordBreak: "keep-all"}}>Level</th>
                                        <th style={{wordBreak: "keep-all"}}>Wallet Address</th>
                                        <th style={{wordBreak: "keep-all"}}>Team Size</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {tableData.length > 0 ? tableData.slice(indexOfFirst, indexOfLast).sort((a, b) => parseInt(a.level) - parseInt(b.level)).map((pack, i) => (
                                        <tr>
                                            <td>{i + indexOfFirst + 1}</td>
                                            <td>{parseInt(pack.level) + 1}</td>
                                            <td>
                                                <Tooltip content={pack.user} customCss={css` white-space: nowrap;`}>
                                                    <span>{pack.user.substring(0, 4)} ... {pack.user.slice(-4)}</span>
                                                </Tooltip>
                                            </td>
                                            <td>
                                                {
                                                    downUserTeam[pack.user] ? downUserTeam[pack.user] : 0
                                                }
                                            </td>

                                        </tr>
                                    )) : <><tr>
                                        <td colSpan={4}>No Data Found</td>
                                    </tr></>}



                                </tbody>
                            </table>
                        </div>
                        {tableData.length == 0 ?
                            ''
                            : <>
                                {pagination <= 1 ? '' : <div >
                                    <Pagination className='myPagination' onChange={pagechange} count={pagination} />
                                </div>}
                            </>
                        }


                    </div>
                </div>

            </section>
            {/* <section className="claimBonus">
                <div className="oc_container">
                    <div className="buttonGrp">
                        <button className="oc_btn" onClick={ClaimReferral} >Claim Bonus</button>
                        <br />
                        {TimeStatus ? <p>Next Claim after <Countdown date={Timer} /></p> : false}

                        <br />
                        <br />
                        <p>You can claim after 27 April 09:00 PM (GMT)</p>
                        <p>You will be claiming 20% of your referral earnings in an interval of 30 days, total 5 claims.</p>
                    </div>
                </div>
            </section> */}


        </>

    )
}

export default Ref