import axios from 'axios';
import { graphURL } from '.';

async function GetReferralDetails(walletAddress) {

    const options = {
        method: 'POST',
        url: graphURL,
        headers: {
            'content-type': 'application/json'
        },
        data: {
            query: `{
                referrals(where:{user:"${walletAddress}"}) {
                totalReferral
                referrer
                totalTeam
              }
              
             
                referralLevels(where:{referrer:"${walletAddress}"}) {
                  user
                  level
                  blockTimestamp
                }
              }`
        }
    };

    const response = await axios.request(options);
    const res = response.data;

    return res
}

export default GetReferralDetails;