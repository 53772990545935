import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import "@coreui/coreui/dist/css/coreui.min.css";
import Sale from "./Pages/Sale/Sale";
import SaleDetails from "./Pages/SaleDetails/SaleDetails";
import Ref from "./Pages/RefDetails/RefDetails";
import { useLocation } from "react-router-dom";
import BigBangStarField from 'react-big-bang-star-field'
import ReactPlayer from 'react-player'
import bgVideo from './Assets/images/bgVideo.mp4'
const Cursor = () => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const cursor = document.querySelector('.cursor');
      cursor.setAttribute('style', `top: ${e.pageY - 15}px; left: ${e.pageX - 20}px;`);
    };

    // const handleClick = (e) => {
    //   const effect = document.createElement('div');
    //   effect.className = 'cursoreffect';
    //   effect.setAttribute('style', `top: ${e.pageY - 16}px; left: ${e.pageX - 21}px;`);
    //   document.body.appendChild(effect);
    //   effect.addEventListener('animationend', function () {
    //     effect.parentElement.removeChild(effect);
    //   });
    // };

    document.addEventListener('mousemove', handleMouseMove);
    // document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      // document.removeEventListener('click', handleClick);
    };
  }, []);

  return null;
};
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {

  return (
    <>
    <div className="cursor"></div>
      <Cursor />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Sale />} />
            {/* <Route path="saledetails" element={<SaleDetails />} /> */}

            <Route path="referdetails" element={<Ref />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <BigBangStarField
        className="bgBody"
        numStars={50}
        maxStarSpeed={5}
        scale={1}
        starColor={"252, 255, 255"}
      />
      {/* <ReactPlayer
        className='react-player bgVideoBody'
        url='https://ik.imagekit.io/imgdata/hashfair/bgVideo.mp4?updatedAt=1687944266890'
        width='100%'
        height='100%'
        muted
        loop
        playing="true"
        playsinline="true"
      /> */}
    </>
  );
}

export default App;
