import axios from 'axios';
import { graphURL } from '.';

async function Top10() {

    const options = {
        method: 'POST',
        url: graphURL,
        headers: {
            'content-type': 'application/json'
        },
        data: {
            query: `{
                referrals(orderBy:totalReferral, orderDirection:desc, first:10) {
                    user
                    totalReferral
                  }
              }`
        }
    };

    const response = await axios.request(options);
    const res = response.data;
  
    return res
}

export default Top10;