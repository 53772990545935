import React, { useEffect, useState } from 'react'
import './PublicSale.css'
import ReactPlayer from 'react-player'
import banerVideo from '../../Assets/images/banner-video.mp4'
import ScrollAnimation from 'react-animate-on-scroll';
import ProgressiveImage from "react-progressive-graceful-image";
import CopyToClipboard from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import copyIcon from '../../Assets/images/copyicon.png'
import { Link, useLocation } from 'react-router-dom';
import { ReferralContract, defaultWeb3 } from '../../config';
import { ethers } from 'ethers';
import ReferralABI from "../../Assets/Abi/OcSale.json"
import { IKImage } from 'imagekitio-react';
import Typewriter from 'typewriter-effect';


const imageKitURL = "https://ik.imagekit.io/mvzhp2pwu/"
const image = "banner-video.gif"


const PublicSale = () => {

    const [walletAddress, setwalletAddress] = useState(localStorage.getItem('walletaddress'))
    const [CheckStatus, setCheckStatus] = useState("")
    const [referrerCode, setreferrerCode] = useState("")
    const [loader, setLoader] = useState(false)
    const location = useLocation();


    const notify = () => toast.success('Copied Successfully!')
    window.addEventListener('storage', () => {
        const walletAddress = localStorage.getItem('walletaddress');
        setwalletAddress(walletAddress)

    })

    const CheckLink = async () => {
        let signer;
        if (window.ethereum) {
            try {
                const metaMaskProvider = new ethers.providers.Web3Provider(
                    window.ethereum
                );
                const accounts = await metaMaskProvider.listAccounts();
                if (accounts.length) {
                    signer = metaMaskProvider.getSigner();
                } else {
                    signer = new ethers.providers.Web3Provider(
                        defaultWeb3.currentProvider
                    );
                }
            } catch (exception) {
                console.error("Unable to list accounts", exception);
            }
        } else {
            signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider);
        }

        const Instance = new ethers.Contract(ReferralContract, ReferralABI, signer);
        if (walletAddress !== null) {
            const Check = await Instance.isReferrer(walletAddress)
            setCheckStatus(Check)
        }
    }

    const GenerateRefferal = async () => {
        setLoader(true)
        let signer;
        if (window.ethereum) {
            try {
                const metaMaskProvider = new ethers.providers.Web3Provider(
                    window.ethereum
                );
                const accounts = await metaMaskProvider.listAccounts();
                if (accounts.length) {
                    signer = metaMaskProvider.getSigner();
                } else {
                    signer = new ethers.providers.Web3Provider(
                        defaultWeb3.currentProvider
                    );
                }
            } catch (exception) {
                console.error("Unable to list accounts", exception);
            }
        } else {
            signer = new ethers.providers.Web3Provider(defaultWeb3.currentProvider);
        }

        const Instance = new ethers.Contract(ReferralContract, ReferralABI, signer);
        try {

            const generateLink = await Instance.generateReferral(referrerCode)
            setLoader(false)
            setCheckStatus(true)
        } catch (error) {
            toast.error(`Error - ${error.reason ? error.reason : (error.data ? error.data.message : error.message)}`)
            // console.log(error);
            setLoader(false)

        }
    }

    async function addTestnetToMetamask() {

        try {
            // check if the chain to connect to is installed
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x89' }], // chainId must be in hexadecimal numbers
            });
            toast.success('Already Exist')

        } catch (error) {

            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: '0x89',
                            chainName: "Polygon Chain",
                            rpcUrls: ['https://polygon-rpc.com'],
                            nativeCurrency: {
                                name: "MATIC",
                                symbol: "MATIC", // 2-6 characters long
                                decimals: 18
                            },
                        },
                    ],
                });
                // alert("Chain Added!")
            } catch (addError) {
                // alert(JSON.stringify(addError));
                console.error(addError);
            }
            // }
            // alert(JSON.stringify(error));
            console.error(error);
        }
    }

    useEffect(() => {
        CheckLink()
        const queryParams = new URLSearchParams(location.search);
        const referrerCode = queryParams.get('referrer');
        setreferrerCode(referrerCode)

    }, [])

    useEffect(() => {
        const inter = setInterval(() => CheckLink(), 3000)
        return () => clearInterval(inter)
    }, [walletAddress])



    return (
        <>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <section className="publicSale">
                <div className="oc_container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-sm-12">
                            <div className="infobox">
                                <ScrollAnimation animateIn="flipInX"
                                    initiallyVisible={true}
                                    animateOnce={true}>
                                    <h1>Unlock Rewards Empower Connections</h1>
                                    
                                    <p className="mt-4"><span>
                                    <Typewriter
                                         options={{
                                            strings: ['Harness the Power of Referrals with HashFair!'],
                                            autoStart: true,
                                            loop: true,
                                            delay: 50,        
                                            cursor: '',       
                                            deleteSpeed: 50, 
                                          }}
                                       
                                    />
                                        </span></p>

                                    {/* {(!CheckStatus && referrerCode) ?
                                        <p className='p-3 spon' style={{ color: "#fff", border: "3px solid #92fef2",borderRadius:"6px"}}>Your Sponser {referrerCode.substring(0, 3)}...{referrerCode.slice(-3)}</p> : <></>} */}
                                    {walletAddress &&
                                        <>
                                            {CheckStatus == true &&
                                                <>
                                                    <h3 className='mt-4'>Copy Referral Link</h3>
                                                    <div className="buttonGrp d-flex flex-wrap justify-content-center justify-content-md-start">
                                                        <CopyToClipboard text={`https://referral.hashfair.io?referrer=${walletAddress}`}>
                                                            <button onClick={notify} className="oc_btn copyRefferalCode reflink"><span>{`https://ref ... ${walletAddress.slice(-3)}`}</span> <img src={copyIcon} alt="Copy icon" /></button>
                                                        </CopyToClipboard>
                                                        <Link to="/referdetails" className='text-decoration-none'> <button className="oc_btn reflink1">My Community</button></Link>
                                                    </div>
                                                </>
                                                ||

                                                <div className="buttonGrp d-flex flex-wrap justify-content-center justify-content-md-start">
                                                    <button class="oc_btn ms-0 reflink" onClick={addTestnetToMetamask} >Add Polygon Mainnet</button>
                                                    <button onClick={GenerateRefferal} className={`oc_btn reflink1 copyRefferalCode ${loader == true && "p-0 justify-content-center"}`}>{
                                                        loader == false &&
                                                        <>
                                                            <span>Generate Referral Link</span>
                                                        </>
                                                        ||
                                                        <div class="loader-wrapper">
                                                            <div class="loader">
                                                                <div class="loader loader-inner"></div>
                                                            </div>
                                                        </div>
                                                    }</button>
                                                </div>}
                                        </>
                                    }
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="imageBox" style={{ overflow: "hidden" }}>
                                {/* <img src={OCAnim} alt="OX Change" width="500" height="500" /> */}
                                {/* <ProgressiveImage delay={0} src={banerVideo} placeholder={banerVideo}>
                                    {(src) => <img src={src} alt="OX Change" />}
                                </ProgressiveImage> */}
                                {/* <ReactPlayer
                                    style={{zIndex: -999999}}
                                    className='react-player bannerVideo'
                                    url={`https://referral.hashfair.io/banner-video.mp4`}
                                    width='100%'
                                    height='100%'
                                    muted={true}
                                    loop={true}
                                    playing={true}
                                    playsinline={true}
                                    pip={false}
                                    controls={false}
                                /> */}

                                <IKImage
                                    urlEndpoint={imageKitURL}
                                    path={image}
                                    width="480"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PublicSale