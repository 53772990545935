import React from "react";
import {CAccordion,CAccordionItem,CAccordionHeader,CAccordionBody} from "@coreui/react";
import './Faq6.css'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
const Faq6 = () => {
  return (
    <>
    <section className="faq">
        <div className="container">
            <h2 className="title-box">F.A.Q.</h2>
        <CAccordion flush>
            <CAccordionItem itemKey={1}>
                <CAccordionHeader>Will I Get referral on referring a user to buy HFG?</CAccordionHeader>
                <CAccordionBody>
                <p>
               <ArrowRightIcon/> You will receive a referral when a user uses your referral link & buys HFG in seed round of 7 days.
                </p>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={2}>
                <CAccordionHeader>When does the HashFair Games Seed Round begin and how long does it last?</CAccordionHeader>
                <CAccordionBody>
                <p>
                <ArrowRightIcon/>  The HashFair Games Seed Round starts on July 1st, 2023, and lasts for a duration of 7 days.
                </p>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={3}>
                <CAccordionHeader> Are there referral bonuses available during the Seed Round?</CAccordionHeader>
                <CAccordionBody>
                <p>
                <ArrowRightIcon/>  Yes, there is a referral program with three levels. The 1st level offers a referral bonus of 2%, while the 2nd and 3rd levels offer 1% each. Additionally, for each successful referral, you will receive $5 worth of HUSD tokens as a bonus.
                </p>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={4}>
                <CAccordionHeader>Are there any requirements to claim the referral bonuses?</CAccordionHeader>
                <CAccordionBody>
                <p>
                <ArrowRightIcon/> Yes, to claim the referral bonuses, you need to meet the minimum criteria of $10 in the Seed Round. The exact contribution amount required may vary, so it is advisable to refer to the official guidelines or documentation provided by HashFair Games for the specific requirements.
                </p>
                </CAccordionBody>
            </CAccordionItem>
            {/* <CAccordionItem itemKey={5}>
                <CAccordionHeader>Accordion Item #3</CAccordionHeader>
                <CAccordionBody>
                <p>
                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the
                collapse plugin adds the appropriate classes that we use to style each element. These classes
                control the overall appearance, as well as the showing and hiding via CSS transitions. You can
                modify any of this with custom CSS or overriding our default variables. It's also worth noting
                that just about any HTML can go within the <code>.accordion-body</code>, though the transition
                does limit overflow.
                </p>
                </CAccordionBody>
            </CAccordionItem> */}
        </CAccordion>
        </div>
    </section>
    </>
  );
};

export default Faq6;
