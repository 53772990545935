import React, { useEffect, useState } from 'react'
import './SaleDetailList.css'
import Top10 from '../../config/Top10';
import { css } from "styled-components"
import { Tooltip } from '@mui/material';


const SaleDetailList = () => {

    const [Data, setData] = useState([])



    async function Table() {
        const data = await Top10()
        setData(data.data.referrals)
    }

    useEffect(() => {
        Table()
    }, [])




    return (
        <>
            <section className="saleDetails" style={{ marginTop: "120px" }}>
                <div className="oc_container">
                    <div className="title">
                        <h2>Referral Leaderboard</h2>
                    </div>
                    <div className="saleDetailsList">
                        <div className='tabletop'>
                            <div className='table-responsive'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sr.no</th>
                                            <th>Wallet Address</th>
                                            <th>Total Referrals</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            Data.length > 0 ?
                                                Data.map((value, idx) =>
                                                    <tr>
                                                        <td>{idx + 1}.</td>
                                                        <td>
                                                            <Tooltip content={value.user} customCss={css` white-space: nowrap;`}>
                                                                <span>{value.user.substring(0, 4)} ... {value.user.slice(-4)}</span>
                                                            </Tooltip>
                                                        </td>
                                                        <td>{value.totalReferral}</td>
                                                    </tr>
                                                ) : <>
                                                    <tr>
                                                        <td colSpan={3}>Loading...</td>
                                                    </tr>
                                                </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Show Details */}

        </>

    )
}

export default SaleDetailList