
export default function Info() {


    return (<>
       <div className=" infosec ">
        <div className="infosecbox">
            <h2>
             Benefits of Seed Round
            </h2>
            1. Exclusive NFT Rewards: During the seed round, complete a total transaction of either $10,000 or $50,000, whether in parts or a single transaction, and receive fantastic NFT rewards. For a $10,000 transaction, you'll receive an exclusive NFT worth $10,000. If you go with  $50,000 transaction, you'll be rewarded with not just one, but two NFTs - one worth $50,000 and another worth $10,000. Build your NFT collection while participating in the future of gaming!
        </div>
        <div className="infosecbox">
            <h2>
            HashFair's Referral Program
            </h2>
            1. Direct Referral Level (2%): When you refer someone directly to HashFair, you will earn a referral bonus of 2% based on their contributions. It's a great way to earn rewards for introducing new participants to the HashFair platform.
            <br></br>
            <br></br>
            2. Second Referral Level (1%): In addition to the direct referrals, you also earn a 1% referral bonus based on the contributions made by individuals referred by your direct referrals. This second level extends your earning potential beyond your immediate referrals.
            <br></br>
            <br></br>
            3. Third Referral Level (1%): Going even further, HashFair rewards you with a 1% referral bonus based on the contributions made by individuals referred by your second level of referrals. This third level allows you to benefit from the network effect and maximize your rewards.
            <br/>
            <br/>
           <span style={{color:'#d0a3ff', fontWeight:"bold"}}>
            
             "Referral Bonus: Earn an extra bonus of HUSD tokens worth $5 by referring your friends to HashFair Games! Remember, to claim this bonus, you must meet the minimum contribution of $10 criteria in our seed round. Share the excitement and unlock the rewards together!"
            </span>
        </div>
       </div>
    </>)
}